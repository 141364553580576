import { useMemo } from 'react';
import {
    Pagination,
    SortDescriptor,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { BasicPage } from '@@/layout/BasicPage';
import { useAppIntl } from '@@/intl';
import { FilteredList } from './FilteredList';
import { ActionBar } from './ActionBar';
import { useProfileList } from './useProfilesList';
import { ColumnManager } from './ColumnManager';
import { FilterManager } from './FilterManager';
import './style.css';

export function ProfileList() {
    const intl = useAppIntl();
    const {
        handlePage,
        handlePageSize,
        handleSort,
        handleCreate,
        handleSearch,
        handleEdit,
        handleDelete,
        handleSetActive,
        handleModalStatus,
        query: { page, size, orderBy, direction },
        data: { loading, items, totalItems },
        modalStatus,
    } = useProfileList();

    const sortDescriptor = useMemo<SortDescriptor>(
        () => ({
            column: orderBy,
            direction,
        }),
        [orderBy, direction],
    );

    return (
        <BasicPage
            breadCrumb={[intl.profiles.profiles()]}
            actionBar={
                <ActionBar
                    onCreate={handleCreate}
                    onSearch={handleSearch}
                    onManageColumns={() =>
                        handleModalStatus({
                            columnsOpen: !modalStatus.columnsOpen,
                        })
                    }
                    onManageFilters={() =>
                        handleModalStatus({
                            filtersOpen: !modalStatus.filtersOpen,
                        })
                    }
                />
            }
            className="profile-list"
        >
            <div className="__filtered-list">
                <FilteredList
                    loading={loading}
                    profiles={items}
                    sort={sortDescriptor}
                    onEdit={handleEdit}
                    onSetActive={handleSetActive}
                    onDelete={handleDelete}
                    onSort={handleSort}
                />
                <Pagination
                    page={page}
                    pageSize={size}
                    totalItems={totalItems}
                    onChangePage={handlePage}
                    onChangePageSize={handlePageSize}
                />
            </div>
            <ColumnManager
                isOpen={modalStatus.columnsOpen}
                onOpenChange={(columnsOpen) =>
                    handleModalStatus({ columnsOpen })
                }
            />
            <FilterManager
                isOpen={modalStatus.filtersOpen}
                onOpenChange={(filtersOpen) =>
                    handleModalStatus({ filtersOpen })
                }
            />
        </BasicPage>
    );
}
