import { useEffect, useState } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { WorkflowListItem } from '@@/types';
import { useAppIntl } from '@@/intl';
import { list } from '@@/api/workflows';

export function useFetchWorkflows({
    filter,
    refreshKey,
}: {
    filter: string;
    refreshKey: any;
}) {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<FetchWorkflowsState>({
        loading: false,
        items: [],
        totalItems: 0,
    });

    useEffect(() => {
        setData({ loading: true, items: [], totalItems: 0 });
        list({ filter })
            .then(({ items, totalItems }) =>
                setData((s) => ({ ...s, items, totalItems })),
            )
            .catch(() =>
                toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
            )
            .finally(() => setData((s) => ({ ...s, loading: false })));
    }, [filter, refreshKey, toast, intl]);

    return data;
}

type FetchWorkflowsState = {
    loading: boolean;
    items: WorkflowListItem[];
    totalItems: number;
};
