import {
    defineMessages,
    useWfIntl,
} from '@rf-smart-for-oraclecloud/platform-ui/i18n';

export const definedMessages = {
    menu: defineMessages({
        app_accounts: {
            defaultMessage: 'Accounts',
            description: 'name of accounts app',
        },
        app_workflowAdmin: {
            defaultMessage: 'Workflows',
            description: 'name of workflows app',
        },
    }),
    settings: defineMessages({
        settings: {
            defaultMessage: 'Settings',
            description: 'Settings',
        },
    }),
    sessions: defineMessages({
        sessionAnalyzer: {
            defaultMessage: 'Session Analyzer',
            description: 'Session Analyzer',
        },
    }),
    profiles: defineMessages({
        actions: {
            defaultMessage: 'Actions',
            description: 'Actions',
        },
        activate: {
            defaultMessage: 'Activate',
            description: 'Activate',
        },
        activatedToast: {
            defaultMessage: 'Profile activated',
            description: 'Profile activated',
        },
        addEntry: {
            defaultMessage: 'Add Entry',
            description: 'Add Entry',
        },
        assign: {
            defaultMessage: 'Assign',
            description: 'Assign',
        },
        assignedUserGroups: {
            defaultMessage: 'Assigned User Groups',
            description: 'Assigned User Groups',
        },
        assignedUserGroupsDescription: {
            defaultMessage: 'Removable user groups are listed below',
            description: 'Removable user groups are listed below',
        },
        availableUserGroups: {
            defaultMessage: 'Available User Groups',
            description: 'Available User Groups',
        },
        availableUserGroupsDescription: {
            defaultMessage: 'Assignable user groups are listed below',
            description: 'Assignable user groups are listed below',
        },
        configuration: {
            defaultMessage: 'Configuration',
            description: 'Configuration',
        },
        createProfile: {
            defaultMessage: 'Create',
            description: 'Create',
        },
        deactivate: {
            defaultMessage: 'Deactivate',
            description: 'Deactivate',
        },
        deactivatedToast: {
            defaultMessage: 'Profile deactivated',
            description: 'Profile deactivated',
        },
        deactivateTitle: {
            defaultMessage: "Deactivate profile ''{name}''",
            description: 'Deactivate title',
        },
        deactivateWarning: {
            defaultMessage:
                'Deactivating this profile will cause user groups assigned to the profile to lose access to workflow entries.  Are you sure you want to deactivate the profile?',
            description: 'Deactivate warning',
        },
        deleteTitle: {
            defaultMessage: "Delete profile ''{name}''",
            description: 'Delete title',
        },
        deleteWarning: {
            defaultMessage:
                "Are you sure you want to delete the profile? Type 'DELETE' to confirm.",
            description: 'Delete warning',
        },
        deletedToast: {
            defaultMessage: 'Profile deleted',
            description: 'Profile deleted',
        },
        deleteFailedToast: {
            defaultMessage: 'There was an error deleting the profile',
            description: 'There was an error deleting the profile',
        },
        details: {
            defaultMessage: 'Basic Details',
            description: 'Basic Details',
        },
        entries: {
            defaultMessage: 'Entries',
            description: 'Entries',
        },
        entryVersionText: {
            defaultMessage: `{version, select,
                Published {Published}
                Latest {Latest}
                other {Published}
            }`,
            description: 'Is Active switch',
        },
        isActiveText: {
            defaultMessage: `{isActive, select,
                true {ACTIVE}
                false {INACTIVE}
                other {INACTIVE}
            }`,
            description: 'Is Active switch',
        },
        latest: {
            defaultMessage: 'Latest Version',
            description: 'Latest Version',
        },
        noEntries: {
            defaultMessage: 'No Entries',
            description: 'No Entries',
        },
        profileEntries: {
            defaultMessage: 'Profile Entries',
            description: 'Profile Entries',
        },
        profiles: {
            defaultMessage: 'Profiles',
            description: 'Profiles',
        },
        profileSavedToast: {
            defaultMessage: 'Profile saved',
            description: 'Profile saved',
        },
        profileSaveFailedToast: {
            defaultMessage: 'Profile did not save successfully',
            description: 'Profile did not save successfully',
        },
        profileIsActiveUpdateFailedToast: {
            defaultMessage:
                "There was an error updating the profile's activation status",
            description:
                "There was an error updating the profile's activation status",
        },
        profileFetchFailedToast: {
            defaultMessage: 'There was an error retreiving the profile',
            description: 'There was an error retreiving the profile',
        },
        profilesFetchFailedToast: {
            defaultMessage: 'There was an error retreiving profiles',
            description: 'There was an error retreiving profiles',
        },
        published: {
            defaultMessage: 'Published',
            description: 'Published',
        },
        searchProfiles: {
            defaultMessage: 'Search Profiles',
            description: 'Search Profiles',
        },
        searchUserGroups: {
            defaultMessage: 'Search User Group',
            description: 'Search User Group',
        },
        selectAll: {
            defaultMessage: 'Select All',
            description: 'Select All',
        },
        status: {
            defaultMessage: 'Status',
            description: 'Status',
        },
        statusAll: {
            defaultMessage: 'All',
            description: 'All',
        },
        statusActive: {
            defaultMessage: 'Active',
            description: 'Active',
        },
        statusInactive: {
            defaultMessage: 'Inactive',
            description: 'Inactive',
        },
        userGroups: {
            defaultMessage: 'User Groups',
            description: 'User Groups',
        },
        userGroupsFetchFailed: {
            defaultMessage:
                'There was an error retreiving assigned user group information',
            description:
                'There was an error retreiving assigned user group information',
        },
        version: {
            defaultMessage: 'Version',
            description: 'Version',
        },
        workflow: {
            defaultMessage: 'Workflow',
            description: 'Workflow',
        },
        workflowsFetchFailed: {
            defaultMessage:
                'There was an error retreiving workflow information',
            description: 'There was an error retreiving workflow information',
        },
        workflowsSaveToast: {
            defaultMessage: 'Workflow saved successfully',
            description: 'Workflow saved successfully',
        },
        workflowsSaveFailedToast: {
            defaultMessage: 'Workflow did not save successfully',
            description: 'Workflow did not save successfully',
        },
    }),
    config: defineMessages({
        addActivity: {
            defaultMessage: 'Add Activity',
            description: 'Add Activity',
        },
        category: {
            defaultMessage: 'Select Category',
            description: 'Select Category',
        },
        codeBehind: {
            defaultMessage: 'Code Behind',
            description: 'Code Behind',
        },
        config: {
            defaultMessage: 'Config',
            description: 'Config',
        },
        createWorkflow: {
            defaultMessage: 'Create Workflow',
            description: 'Create Workflow',
        },
        designer: {
            defaultMessage: 'Designer',
            description: 'Designer',
        },
        enterCategory: {
            defaultMessage: 'Enter category name',
            description: 'Enter category',
        },
        logInfo: {
            defaultMessage: 'Log Info',
            description: 'Log Info',
        },
        body: {
            defaultMessage: 'Body',
            description: 'Body',
        },
        selectCategory: {
            defaultMessage: 'Select Category',
            description: 'Select Category',
        },
        selectionInfo: {
            defaultMessage: 'Selection Info',
            description: 'Selection Info',
        },
        selectActivity: {
            defaultMessage: 'Select Activity',
            description: 'Select Activity',
        },
        selectWorkflowType: {
            defaultMessage: 'Select Type',
            description: 'Select Type',
        },
        tempManualEdit: {
            defaultMessage: 'Temp Manual Edit',
            description: 'Temp Manual Edit',
        },
        workflow: {
            defaultMessage: 'Workflow',
            description: 'Workflow',
        },
        workflowConfiguration: {
            defaultMessage: 'Workflow Configuration',
            description: 'Workflow Configuration',
        },
        workflowDetails: {
            defaultMessage: 'Workflow Details',
            description: 'Workflow Details',
        },
        workflowName: {
            defaultMessage: 'Workflow Name',
            description: 'Workflow Name',
        },
        workflowSavedToast: {
            defaultMessage: 'Workflow Saved',
            description: 'Workflow Saved',
        },
    }),
    common: defineMessages({
        actions: {
            defaultMessage: 'Actions',
            description: 'Actions',
        },
        add: {
            defaultMessage: 'Add',
            description: 'Add',
        },
        cancel: {
            defaultMessage: 'Cancel',
            description: 'Cancel',
        },
        category: {
            defaultMessage: 'Category',
            description: 'Category',
        },
        close: {
            defaultMessage: 'Close',
            description: 'Close',
        },
        configure: {
            defaultMessage: 'Configure',
            description: 'Configure',
        },
        create: {
            defaultMessage: 'Create',
            description: 'Create',
        },
        createdOn: {
            defaultMessage: 'Created on',
            description: 'Created on',
        },
        createdBy: {
            defaultMessage: 'Created by',
            description: 'Created by',
        },
        delete: {
            defaultMessage: 'Delete',
            description: 'Delete',
        },
        description: {
            defaultMessage: 'Description',
            description: 'Description',
        },
        done: {
            defaultMessage: 'Done',
            description: 'Done',
        },
        duplicate: {
            defaultMessage: 'Duplicate',
            description: 'Duplicate',
        },
        edit: {
            defaultMessage: 'Edit',
            description: 'Edit',
        },
        end: {
            defaultMessage: 'End',
            description: 'End',
        },
        existing: {
            defaultMessage: 'Existing',
            description: 'Existing',
        },
        export: {
            defaultMessage: 'Export',
            description: 'Export',
        },
        import: {
            defaultMessage: 'Import',
            description: 'Import',
        },
        manageFilters: {
            defaultMessage: 'Manage Filters',
            description: 'Manage Filters',
        },
        name: {
            defaultMessage: 'Name',
            description: 'Name',
        },
        new: {
            defaultMessage: 'New',
            description: 'New',
        },
        ok: {
            defaultMessage: 'OK',
            description: 'OK',
        },
        output: {
            defaultMessage: 'Output',
            description: 'Output',
        },
        manageColumns: {
            defaultMessage: 'Manage Columns',
            description: 'Manage Columns',
        },
        preview: {
            defaultMessage: 'Preview',
            description: 'Preview',
        },
        publish: {
            defaultMessage: 'Publish',
            description: 'Publish',
        },
        reset: {
            defaultMessage: 'Reset',
            description: 'Reset',
        },
        save: {
            defaultMessage: 'Save',
            description: 'Save',
        },
        search: {
            defaultMessage: 'Search',
            description: 'Search',
        },
        select: {
            defaultMessage: 'Select',
            description: 'Select',
        },
        selectNew: {
            defaultMessage: 'Select New',
            description: 'Select New',
        },
        selectExisting: {
            defaultMessage: 'Select Existing',
            description: 'Select Existing',
        },
        selectTemplate: {
            defaultMessage: 'Select Template',
            description: 'Select Template',
        },
        start: {
            defaultMessage: 'Start',
            description: 'Start',
        },
        template: {
            defaultMessage: 'Template',
            description: 'Template',
        },
        yes: {
            defaultMessage: 'Yes',
            description: 'Yes',
        },
    }),
    validation: defineMessages({
        json: {
            defaultMessage: 'Must be valid json',
            description: 'Field ust be valid json',
        },
        maxLength: {
            defaultMessage: 'Must be less than {max} characters',
            description: 'Field is required',
        },
        required: {
            defaultMessage: 'Required',
            description: 'Field is required',
        },
    }),
};

export const useAppIntl = () => useWfIntl(definedMessages);
