import { useState } from 'react';
import { BasicPage } from '@@/layout/BasicPage';
import { useAppIntl } from '@@/intl';
import { WorkflowListActionBar } from './ActionBar';
import { useWorkflowListStore } from './store';
import { FilteredWorkflowList } from './FilteredWorkflowList';
import { useFetchWorkflows } from './useFetchWorkflows';
import './style.css';

export function WorkflowList() {
    const intl = useAppIntl();
    const filter = useWorkflowListStore((state) => state.list.filter);
    const [refreshKey, setRefreshKey] = useState({});
    const { items, loading } = useFetchWorkflows({ filter, refreshKey });
    const breadCrumb = [intl.config.workflowConfiguration()];

    const refreshList = () => {
        setRefreshKey({});
    };

    return (
        <BasicPage
            breadCrumb={breadCrumb}
            actionBar={<WorkflowListActionBar refreshWorkflows={refreshList} />}
        >
            <FilteredWorkflowList
                workflows={items}
                loading={loading}
                refreshWorkflows={refreshList}
            />
        </BasicPage>
    );
}
