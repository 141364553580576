import { useState } from 'react';
import { IconType } from 'react-icons';
import {
    MdMoreVert,
    MdOutlineModeEdit,
    MdDoNotDisturb,
    MdLightbulbOutline,
    MdDeleteOutline,
} from 'react-icons/md';
import {
    DropdownMenu,
    DropdownMenuItem,
    Key,
    Text,
} from '@rf-smart-for-oraclecloud/ui';
import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { DeactivateModal } from '../common/DeactivateModal';
import { DeleteModal } from '../common/DeleteModal';
import { ProfileActionType } from './types';

export function ProfileActionMenu({
    profile,
    onEdit,
    onSetActive,
    onDelete,
}: ProfileActionMenuProps) {
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const intl = useAppIntl();

    const handleEdit = () => onEdit(profile);
    const handleActivate = () => onSetActive(profile, true);
    const handleDeactivate = () => setDeactivateModalOpen(true);
    const handleCancelDeactivate = () => setDeactivateModalOpen(false);
    const handleSubmitDeactivate = () => onSetActive(profile, false);
    const handleDelete = () => setDeleteModalOpen(true);
    const handleCancelDelete = () => setDeleteModalOpen(false);
    const handleSubmitDelete = () => onDelete(profile);

    const handleMenu = (key: Key) => {
        const actionMap = {
            activate: handleActivate,
            deactivate: handleDeactivate,
            edit: handleEdit,
            delete: handleDelete,
        };
        actionMap[key as ProfileActionType]();
    };

    return (
        <>
            <DropdownMenu
                onAction={handleMenu}
                icon={<MdMoreVert size="2rem" />}
                ariaLabel={intl.profiles.actions()}
            >
                <MenuItem
                    id="edit"
                    icon={MdOutlineModeEdit}
                    label={intl.common.edit()}
                />
                {profile.isActive && (
                    <MenuItem
                        id="deactivate"
                        icon={MdDoNotDisturb}
                        label={intl.profiles.deactivate()}
                    />
                )}
                {!profile.isActive && (
                    <>
                        <MenuItem
                            id="activate"
                            icon={MdLightbulbOutline}
                            label={intl.profiles.activate()}
                        />
                        <MenuItem
                            id="delete"
                            icon={MdDeleteOutline}
                            label={intl.common.delete()}
                            danger
                        />
                    </>
                )}
            </DropdownMenu>
            <DeactivateModal
                profileName={profile.name}
                isOpen={deactivateModalOpen}
                onClose={handleCancelDeactivate}
                onSubmit={handleSubmitDeactivate}
            />
            <DeleteModal
                profileName={profile.name}
                isOpen={deleteModalOpen}
                onClose={handleCancelDelete}
                onSubmit={handleSubmitDelete}
            />
        </>
    );
}

function MenuItem({ label, icon, danger, id }: MenuItemProps) {
    const Icon = icon;
    return (
        <DropdownMenuItem id={id} family={danger ? 'danger' : undefined}>
            <div className="profile-list-menu-item ">
                <Icon size="2rem" />
                <Text slot="label">{label}</Text>
            </div>
        </DropdownMenuItem>
    );
}

type ProfileActionMenuProps = Readonly<{
    profile: Profile;
    onEdit: (profile: Profile) => void;
    onSetActive: (profile: Profile, isActive: boolean) => void;
    onDelete: (profile: Profile) => void;
}>;

type MenuItemProps = Readonly<{
    label: string;
    icon: IconType;
    danger?: boolean;
    id: ProfileActionType;
}>;
