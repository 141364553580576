import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkflowDefinition } from './WorkflowDefinition';
import { WorkflowHeader } from './WorkflowHeader';
import { useFetchWorkflow } from './useFetchWorkflow';
import { loadWorkflow, resetDisplay } from '../store/actions';
import './style.css';

export function Workflow() {
    const ready = useLoadWorkflow();

    if (!ready) {
        return '...Loading';
    }

    return (
        <div className="wf-editor-workflow">
            <div className="wf-editor-workflow__header">
                <WorkflowHeader />
            </div>
            <div className="wf-editor-workflow__definition">
                <WorkflowDefinition />
            </div>
        </div>
    );
}

function useLoadWorkflow() {
    const [ready, setReady] = useState(false);
    const { workflowId } = useParams();
    const { workflow, loading } = useFetchWorkflow(workflowId);

    useEffect(() => {
        if (workflow === undefined || loading) return;

        loadWorkflow(workflow);
        resetDisplay();
        setReady(true);
    }, [workflow, loading]);

    return ready;
}
