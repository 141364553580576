import {
    ListRequest,
    UserGroup,
    api,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { PagedResponse } from '@@/types';
import { apiCodes } from '@@/appConfig';

export const list = ({ filter, page, pageSize }: ListRequest) =>
    api.get<PagedResponse<UserGroup>>(
        apiCodes.accounts,
        `userGroups?filter=${filter ?? ''}&page=${page ?? 1}&size=${pageSize ?? 20}`,
    );
