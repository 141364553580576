import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { SortDescriptor } from '@rf-smart-for-oraclecloud/platform-ui';
import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { list, remove, update } from '@@/api/profiles';
import {
    setData,
    setModalStatus,
    setQuery,
    useProfilesListState,
} from './useProfilesListState';

export function useProfileList() {
    const toast = useToast();
    const intl = useAppIntl();
    const navigate = useNavigate();

    const { query, data, modalStatus } = useProfilesListState();

    useEffect(() => {
        setData({ loading: true, items: [], totalItems: 0 });

        list(query)
            .then(({ items, totalItems }) => setData({ items, totalItems }))
            .catch(() =>
                toast.showErrorToast(intl.profiles.profilesFetchFailedToast()),
            )
            .finally(() => setData({ loading: false }));
    }, [query, toast, intl]);

    const handleModalStatus = setModalStatus;
    const handleSearch = (filter: string) => setQuery({ page: 1, filter });
    const handlePage = (page: number) => setQuery({ page });
    const handlePageSize = (size: number) => setQuery({ page: 1, size });
    const handleSort = (sort: SortDescriptor) =>
        setQuery({ orderBy: sort.column as string, direction: sort.direction });

    const handleCreate = () => navigate('./create');
    const handleEdit = ({ id }: Profile) => navigate(`./${id}`);
    const handleDelete = ({ id }: Profile) => {
        remove(id)
            .then(() => {
                setQuery({ refreshKey: {} });
                toast.showSuccessToast(intl.profiles.deletedToast());
            })
            .catch(() =>
                toast.showErrorToast(intl.profiles.deleteFailedToast()),
            );
    };

    const handleSetActive = (profile: Profile, isActive: boolean) => {
        update({
            ...profile,
            isActive,
        })
            .then(() => {
                setQuery({ refreshKey: {} });
                toast.showSuccessToast(
                    isActive
                        ? intl.profiles.activatedToast()
                        : intl.profiles.deactivatedToast(),
                );
            })
            .catch(() =>
                toast.showErrorToast(
                    intl.profiles.profileIsActiveUpdateFailedToast(),
                ),
            );
    };

    return {
        handleSearch,
        handlePage,
        handlePageSize,
        handleSort,
        handleCreate,
        handleEdit,
        handleDelete,
        handleSetActive,
        handleModalStatus,
        query,
        data,
        modalStatus,
    };
}
