import { Settings } from './Settings';
import { Workflow } from './Workflow';
import './style.css';

export function Editor() {
    return (
        <div className="wf-editor">
            <Workflow />
            <Settings />
        </div>
    );
}
