import { api } from '@rf-smart-for-oraclecloud/platform-ui';
import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { ProfilesListQuery } from '@@/areas/profiles/ProfileList/types';
import { apiCodes } from '@@/appConfig';
import { CreateProfileRequest, PagedResponse } from '../types';

const apiCode = apiCodes.workflows;

export const list = ({ refreshKey, ...query }: ProfilesListQuery) => {
    const { direction, filterColumns, ...queryArgs } = query;

    const queryStringValues = [
        ...Object.entries({
            ...queryArgs,
            orderDesc: direction === 'ascending',
        }).map(([key, value]) => ({ key, value })),
        ...filterColumns.map((fc) => ({ key: 'filterColumns', value: fc })),
    ];

    const queryString = queryStringValues
        .map(({ key, value }) => `${key}=${value}`)
        .join('&');

    return api.get<PagedResponse<Profile>>(apiCode, `profiles?${queryString}`);
};

export const get = (id: string) =>
    api.get<Profile | undefined>(apiCode, `profiles/${id}`);

export const create = (profile: CreateProfileRequest) =>
    api.post<Profile>(apiCode, 'profiles', profile);

export const update = (profile: Profile) =>
    api.put<Profile>(apiCode, `profiles/${profile.id}`, profile);

export const remove = (id: string) => api.remove(apiCode, `profiles/${id}`);

export const listAssignments = (profileId: string) =>
    api.get<string[]>(apiCode, `profiles/${profileId}/assign`);

export const updateAssignments = (
    profileId: string,
    assignedUserGroups: string[],
) =>
    api.post<string[]>(
        apiCode,
        `profiles/${profileId}/assign`,
        assignedUserGroups,
    );
