import { MdOutlinePowerSettingsNew, MdOutlineExitToApp } from 'react-icons/md';
import { ActivityPlaceHolder } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { AddActivityButton } from '../../components/AddActivityButton';
import { Connector } from '../../components/Connector';
import { ActivityList } from './ActivityList';
import { WfBreadCrumb } from './BreadCrumb';

export function WorkflowDefinitionDesigner() {
    return (
        <div className="wf-editor-definition-designer">
            <WfBreadCrumb />
            <div className="wf-editor-definition-designer__activities">
                <Start />
                <ActivityList />
                <End />
            </div>
        </div>
    );
}

function Start() {
    const intl = useAppIntl();
    return (
        <>
            <ActivityPlaceHolder
                label={intl.common.start()}
                icon={MdOutlinePowerSettingsNew}
            />
            <Connector />
            <AddActivityButton index={0} />
        </>
    );
}

function End() {
    const intl = useAppIntl();
    return (
        <>
            <Connector />
            <ActivityPlaceHolder
                label={intl.common.end()}
                icon={MdOutlineExitToApp}
            />
        </>
    );
}
