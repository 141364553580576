import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Label,
    Radio,
    RadioGroup,
    Text,
} from '@rf-smart-for-oraclecloud/ui';
import {
    useForm,
    FormProvider,
    FormRadioInput,
    FormSelectInput,
    FormTextArea,
    FormTextInput,
} from '@rf-smart-for-oraclecloud/wf-web';
import { CreateWorkflowFormData } from '@@/types';
import { BasicModalLayout } from '@@/layout/BasicModal';
import { useAppIntl } from '@@/intl';
import { useCreateWorkflow } from './useCreateWorkflow';
import { useWorkflowCategories } from './useWorkflowCategories';
import { useWorkflowTemplates } from './useWorkflowTemplates';

const formDefaults: CreateWorkflowFormData = {
    category: '',
    name: '',
    description: '',
    template: {
        type: 'new',
    },
};

export function CreateWorkflowForm({
    onClose,
    refreshWorkflows,
}: CreateWorkflowFormProps) {
    const navigate = useNavigate();
    const [categoryType, setCategoryType] = useState<CategoryType>('new');
    const form = useForm<CreateWorkflowFormData>({
        defaultValues: formDefaults,
    });
    const intl = useAppIntl();
    const { createWorkflow, loading } = useCreateWorkflow();
    const { categories } = useWorkflowCategories();
    const { templates, existing } = useWorkflowTemplates();
    const workflowType = form.watch('template.type');

    const handleChangeCategoryType = (value: string) =>
        setCategoryType(value as CategoryType);

    const handleSubmit = () =>
        form.handleSubmit(async (values) => {
            const workflow = await createWorkflow(values);
            refreshWorkflows();

            if (workflow !== undefined) {
                navigate(`./${workflow.id}`);
            }
        })();

    return (
        <BasicModalLayout
            title={intl.config.createWorkflow()}
            size="small"
            onClose={onClose}
            actions={
                <>
                    <Button family="subtle" variant="ghost" onClick={onClose}>
                        {intl.common.cancel()}
                    </Button>
                    <Button onClick={handleSubmit} isDisabled={loading}>
                        {intl.common.save()}
                    </Button>
                </>
            }
        >
            <div className="wf-create">
                <FormProvider {...form}>
                    <Section title={intl.config.category()}>
                        <RadioGroup
                            onChange={handleChangeCategoryType}
                            orientation="horizontal"
                            value={categoryType}
                        >
                            <Radio value="new">{intl.common.new()}</Radio>
                            <Radio value="existing">
                                {intl.common.existing()}
                            </Radio>
                        </RadioGroup>
                        {categoryType === 'new' && (
                            <FormTextInput
                                fieldName="category"
                                placeholder={intl.config.enterCategory()}
                            />
                        )}
                        {categoryType === 'existing' && (
                            <FormSelectInput
                                fieldName="category"
                                placeholder={intl.config.selectCategory()}
                                items={categories}
                                textSelector={(i) => i.name}
                                valueSelector={(i) => i.value}
                            />
                        )}
                    </Section>
                    <Section title={intl.config.workflowDetails()}>
                        <Label className="rfs-FieldLabel">
                            {intl.config.selectWorkflowType()}
                        </Label>
                        <FormRadioInput
                            fieldName="template.type"
                            orientation="horizontal"
                        >
                            <Radio value="new">{intl.common.new()}</Radio>
                            <Radio value="existing">
                                {intl.common.existing()}
                            </Radio>
                            <Radio value="template">
                                {intl.common.template()}
                            </Radio>
                        </FormRadioInput>
                        {workflowType === 'existing' && (
                            <FormSelectInput
                                fieldName="template.id"
                                placeholder={intl.common.selectExisting()}
                                items={existing}
                                textSelector={(i) => i.name}
                                valueSelector={(i) => i.value}
                            />
                        )}
                        {workflowType === 'template' && (
                            <FormSelectInput
                                fieldName="template.id"
                                placeholder={intl.common.selectTemplate()}
                                items={templates}
                                textSelector={(i) => i.name}
                                valueSelector={(i) => i.value}
                            />
                        )}
                        <FormTextInput
                            fieldName="name"
                            label={intl.common.name()}
                        />
                        <FormTextArea
                            fieldName="description"
                            label={intl.common.description()}
                            rows={5}
                        />
                    </Section>
                </FormProvider>
            </div>
        </BasicModalLayout>
    );
}

function Section({
    children,
    title,
}: Readonly<React.PropsWithChildren<{ title: string }>>) {
    return (
        <Form className="wf-create__section">
            <Text elementType="h4">{title}</Text>
            {children}
        </Form>
    );
}

type CreateWorkflowFormProps = Readonly<{
    onClose: () => void;
    refreshWorkflows: () => void;
}>;

type CategoryType = 'new' | 'existing';
