import {
    Breadcrumbs,
    BreadcrumbsItem,
    Key,
} from '@rf-smart-for-oraclecloud/ui';
import {
    ActivityNav,
    ActivitySpecEdit,
    isWebActivity,
} from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/areas/config/plugins';
import { useWorkflowEditorStore } from '../../store';
import { drillToActivity } from '../../store/actions';
import { selectBreadCrumb } from '../../store/selectors';

export function WfBreadCrumb() {
    const breadCrumb = useWorkflowEditorStore(selectBreadCrumb);

    const handleNav = (key: Key) => {
        const bc = breadCrumb.find((a) => a.id === key);
        if (bc !== undefined) {
            drillToActivity(bc.navPath);
        }
    };

    return (
        <Breadcrumbs
            onAction={handleNav}
            className="wf-editor-definition-designer__breadcrumb"
        >
            {breadCrumb.map((bc) => (
                <BreadcrumbsItem id={bc.id} key={bc.id}>
                    {getDisplayText(bc.navPath, bc.spec)}
                </BreadcrumbsItem>
            ))}
        </Breadcrumbs>
    );
}

function getDisplayText(
    navPath: ActivityNav,
    spec: ActivitySpecEdit | undefined,
) {
    if (spec === undefined) return '';

    const activity = plugins.activities[spec.type];
    let result = spec.name;

    if (
        activity !== undefined &&
        isWebActivity(activity) &&
        activity.settings.breadCrumb !== undefined
    ) {
        result = activity.settings.breadCrumb(navPath, spec);
    }

    return result;
}
