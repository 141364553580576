import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { BasicPage } from '@@/layout/BasicPage';
import { ProfileDetailEntryActions } from './Actions';
import {
    EntryDescriptionField,
    EntryNameField,
    EntryWorkflowConfigField,
    EntryWorkflowField,
    EntryWorkflowVersionField,
} from './fields';
import { ProfileForm, ProfileEntryForm } from '../types';
import { createEntry } from '../defaults';
import './style.css';

export function ProfileDetailEntry() {
    const intl = useAppIntl();
    const navigate = useNavigate();
    const { entryId } = useParams();
    const { setValue, watch } = useFormContext<ProfileForm>();
    const [profileName, entries, entry] = watch([
        'name',
        'entries',
        `entries.${entryId}`,
    ]);
    const entryForm = useForm<ProfileEntryForm>({
        mode: 'onTouched',
        defaultValues: createEntry(),
    });
    const entryName = entryForm.watch('name');

    const breadCrumb = useMemo(
        () => [
            { name: intl.profiles.profiles(), nav: '../..' },
            { name: profileName, nav: '..' },
            entryName,
        ],
        [intl, profileName, entryName],
    );

    const submitDisabled = !entryForm.formState.isDirty;
    const showDelete = entryId !== 'create';

    useEffect(() => {
        if (entry !== undefined) {
            entryForm.reset(entry);
        }
    }, [entry, entryForm]);

    const handleSubmit = () =>
        entryForm.handleSubmit((values: ProfileEntryForm) => {
            const { id } = values;
            setValue(`entries.${id}`, values);
            navigate('..');
        })();

    const handleDelete = () => {
        const { [entry.id]: test, ...rest } = entries;
        setValue('entries', rest);
        navigate('..');
    };

    const handleCancel = () => navigate('..');

    if (entryId === undefined) {
        return 'Not Found';
    }

    return (
        <FormProvider {...entryForm}>
            <BasicPage
                breadCrumb={breadCrumb}
                className="profile-detail-entry"
                actionBar={
                    <ProfileDetailEntryActions
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        onDelete={handleDelete}
                        showDelete={showDelete}
                        submitDisabled={submitDisabled}
                    />
                }
            >
                <Form className="__content">
                    <EntryNameField />
                    <EntryDescriptionField />
                    <EntryWorkflowField />
                    <EntryWorkflowVersionField />
                    <EntryWorkflowConfigField />
                </Form>
            </BasicPage>
        </FormProvider>
    );
}
