import { Button, Text } from '@rf-smart-for-oraclecloud/ui';
import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import { useAppIntl } from '@@/intl';
import { addActivity } from '../../../store/actions';
import { useSaveWorkflow } from '../../../Workflow/useSaveWorkflow';

export function AddActivityCard({ activity }: AddActivityCardProps) {
    const intl = useAppIntl();
    const { saveWorkflow } = useSaveWorkflow();
    const handleAdd = () => {
        addActivity(activity);
        saveWorkflow();
    };

    return (
        <div className="__activity-card">
            <div>
                <Text elementType="h6">{activity.name}</Text>
                <Text>{activity.description}</Text>
            </div>
            <Button family="subtle" onClick={handleAdd}>
                {intl.common.add()}
            </Button>
        </div>
    );
}

type AddActivityCardProps = Readonly<{
    activity: WorkflowActivity;
}>;
