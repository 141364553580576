import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import {
    ActivityConfigBase,
    ActivityEditorLayout,
    ActivitySpecEdit,
    isWebActivity,
} from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/areas/config/plugins';
import {
    clearSelection,
    updateActivitySpec,
} from '@@/areas/config/Editor/store/actions';
import { useWorkflowEditorStore } from '@@/areas/config/Editor/store';
import { selectDesignerContext } from '@@/areas/config/Editor/store/selectors';
import { useSaveWorkflow } from '../../../Workflow/useSaveWorkflow';

export function ActivitySettings({ spec }: ActivitySettingsProps) {
    const activity = plugins.activities[spec.type];
    const { saveWorkflow } = useSaveWorkflow();
    const designerContext = useWorkflowEditorStore(selectDesignerContext);

    const handleSave = (value: ActivityConfigBase) => {
        updateActivitySpec({
            key: spec.key,
            type: spec.type,
            ...value,
        });
        saveWorkflow();
    };

    const Editor = getSettingsEditor(activity);
    if (Editor === undefined) {
        return (
            <ActivityEditorLayout>
                <ActivityEditorLayout.Header />
                <ActivityEditorLayout.Content />
            </ActivityEditorLayout>
        );
    }

    return (
        <Editor
            plugins={plugins}
            activityKey={spec.key}
            defaultValue={spec}
            onSave={handleSave}
            onCancel={clearSelection}
            designerContext={designerContext}
        />
    );
}

function getSettingsEditor(activity: WorkflowActivity) {
    if (!isWebActivity(activity) || activity.settings === undefined) {
        return undefined;
    }

    return activity.settings.editor;
}

type ActivitySettingsProps = Readonly<{
    spec: ActivitySpecEdit;
}>;
