import { useMemo } from 'react';
import {
    Table,
    TableHeader,
    Column,
    TableBody,
    Row,
} from '@rf-smart-for-oraclecloud/ui';
import {
    NoItems,
    Loading,
    SortDescriptor,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { useProfilesListViewSettingsStore } from './useProfilesListViewSettingsStore';
import { FilteredProfileListActions, ProfileListColumn } from './types';
import { actionsColumn, columnDefinitions, nameColumn } from './columns';

export function FilteredList({
    loading,
    profiles,
    sort,
    onEdit,
    onSetActive,
    onDelete,
    onSort,
}: FilteredProfileListProps) {
    const intl = useAppIntl();
    const columns = useColumns();

    return (
        <Table
            onSortChange={onSort}
            sortDescriptor={sort}
            aria-label={intl.profiles.profiles()}
            selectionMode="none"
        >
            <TableHeader>
                {columns.map((column) => (
                    <Column
                        id={column.key}
                        key={column.key}
                        isRowHeader={columns.indexOf(column) === 0}
                        allowsSorting={column.allowSort}
                    >
                        {column.isActions ? '' : column.label({ intl })}
                    </Column>
                ))}
            </TableHeader>
            <TableBody
                items={profiles}
                renderEmptyState={() => <EmptyState loading={loading} />}
            >
                {profiles.map((profile) => (
                    <ProfileRow
                        key={profile.id}
                        columns={columns}
                        profile={profile}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onSetActive={onSetActive}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

function ProfileRow({
    columns,
    profile,
    onEdit,
    onDelete,
    onSetActive,
}: Readonly<
    {
        columns: ProfileListColumn[];
        profile: Profile;
    } & FilteredProfileListActions
>) {
    const intl = useAppIntl();
    return (
        <Row id={profile.id} onAction={() => onEdit(profile)} columns={columns}>
            {(column) =>
                column.template({
                    intl,
                    profile,
                    onEdit,
                    onDelete,
                    onSetActive,
                })
            }
        </Row>
    );
}

function EmptyState({ loading }: Readonly<{ loading: boolean }>) {
    if (loading) {
        return (
            <div className="__loading-wrapper">
                <Loading isLoading />
            </div>
        );
    }

    return <NoItems />;
}

function useColumns() {
    const columns = useProfilesListViewSettingsStore((s) => s.columns);

    const result: ProfileListColumn[] = useMemo(
        () => [
            {
                key: 'name',
                ...nameColumn,
            },
            ...columns.map((key) => ({
                key,
                ...columnDefinitions[key],
            })),
            {
                key: 'actions',
                ...actionsColumn,
            },
        ],
        [columns],
    );

    return result;
}

type FilteredProfileListProps = Readonly<
    {
        profiles: Profile[];
        loading: boolean;
        sort: SortDescriptor;
        onSort: (descriptor: SortDescriptor) => void;
    } & FilteredProfileListActions
>;
