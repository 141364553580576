import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { WorkflowEditorStore } from '../types';

export const useWorkflowEditorStore = create(
    devtools(
        immer<WorkflowEditorStore>(() => ({
            display: 'design',
            navigation: [{ key: 'root', childKey: 'default' }],
            selection: undefined,
            workflow: undefined,
        })),
    ),
);

export const { getState, setState } = useWorkflowEditorStore;
