import { useState, useEffect } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { WorkflowDetail } from '@@/types';
import { useAppIntl } from '@@/intl';
import { get } from '@@/api/workflows';

export function useFetchWorkflow(workflowId: string | undefined) {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<FetchWorkflowState>({
        loading: false,
        workflow: undefined,
    });

    useEffect(() => {
        if (workflowId === undefined) return;

        setData({ loading: true, workflow: undefined });
        get(workflowId)
            .then((workflow) => setData((s) => ({ ...s, workflow })))
            .catch(() =>
                toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
            )
            .finally(() => setData((s) => ({ ...s, loading: false })));
    }, [workflowId, intl, toast]);

    return data;
}

type FetchWorkflowState = {
    loading: boolean;
    workflow?: WorkflowDetail;
};
