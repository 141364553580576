import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { create, remove, update, updateAssignments } from '@@/api/profiles';
import { useAppIntl } from '@@/intl';
import { ProfileForm } from '../types';
import { fromProfileForm } from '../utils';

export function useProfileActions() {
    const intl = useAppIntl();
    const toast = useToast();
    const { profileId } = useParams();
    const isCreate = profileId === 'create';
    const navigate = useNavigate();
    const { handleSubmit, formState, setValue } = useFormContext<ProfileForm>();

    const onSubmit = async (form: ProfileForm) => {
        const { id, profile, userGroups } = fromProfileForm(form);

        try {
            const newProfile = await (id === undefined
                ? create(profile)
                : update({ id, ...profile }));

            await updateAssignments(newProfile.id, userGroups);

            navigate('..');

            toast.showSuccessToast(intl.profiles.profileSavedToast());
        } catch {
            toast.showErrorToast(intl.profiles.profileSaveFailedToast());
        }
    };

    const handleSave = () => handleSubmit(onSubmit)();

    const handleCancel = () => {
        navigate('..');
    };

    const handleSetActive = (isActive: boolean) => () => {
        const { defaultValues } = formState;
        const { id, profile } = fromProfileForm(defaultValues as ProfileForm);

        if (id !== undefined) {
            update({
                id,
                ...profile,
                isActive,
            })
                .then(() => {
                    setValue('isActive', isActive);
                    toast.showSuccessToast(
                        isActive
                            ? intl.profiles.activatedToast()
                            : intl.profiles.deactivatedToast(),
                    );
                })
                .catch(() =>
                    toast.showErrorToast(
                        intl.profiles.profileIsActiveUpdateFailedToast(),
                    ),
                );
        }
    };

    const handleDelete = () => {
        if (profileId !== undefined) {
            remove(profileId)
                .then(() => {
                    navigate('..');
                    toast.showSuccessToast(intl.profiles.deletedToast());
                })
                .catch(() =>
                    toast.showErrorToast(intl.profiles.deleteFailedToast()),
                );
        }
    };

    return {
        handleSave,
        handleCancel,
        handleSetActive,
        handleDelete,
        isCreate,
    };
}
