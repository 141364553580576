import { WorkflowDesignerContext } from '@rf-smart-for-oraclecloud/wf-web';
import { WorkflowEditorStore } from '../types';

export const selectSelectedActivity = (state: WorkflowEditorStore) =>
    state.workflow === undefined ||
    state.selection === undefined ||
    state.selection.type !== 'activity'
        ? undefined
        : state.workflow.activityMap[state.selection.id];

export const selectCurrentActivityNodes = (state: WorkflowEditorStore) => {
    if (state.workflow === undefined) return [];

    let currentActivities = state.workflow.activityTree;

    state.navigation.forEach((navPath) => {
        currentActivities =
            currentActivities.find((c) => c.key === navPath.key)?.children?.[
                navPath.childKey
            ] ?? [];
    });

    return currentActivities;
};

export const selectCurrentActivities = (state: WorkflowEditorStore) =>
    selectCurrentActivityNodes(state).map(
        (a) => state.workflow?.activityMap[a.key],
    );

export const selectBreadCrumb = (state: WorkflowEditorStore) =>
    state.navigation.map((navPath) => ({
        id: `${navPath.key}-${navPath.childKey}`,
        navPath,
        spec: state.workflow?.activityMap[navPath.key],
    }));

export const selectDesignerContext = (
    state: WorkflowEditorStore,
): WorkflowDesignerContext => {
    const { activityMap = {}, activityTree = [] } = state.workflow ?? {};
    return {
        activityMap,
        activityTree,
    };
};
