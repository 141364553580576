import { Button, Form } from '@rf-smart-for-oraclecloud/ui';
import {
    ActivityEditorLayout,
    FormProvider,
    FormTextArea,
    FormTextInput,
    useForm,
} from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { useWorkflowEditorStore } from '../../../store';
import { clearSelection, updateWorkflowSpec } from '../../../store/actions';
import { useSaveWorkflow } from '../../../Workflow/useSaveWorkflow';
import { WorkflowSettingsConfig } from '../../../types';

export function WorkflowSettings() {
    const intl = useAppIntl();
    const { saveWorkflow } = useSaveWorkflow();
    const workflowRoot = useWorkflowEditorStore((state) => state.workflow);
    const form = useForm<WorkflowSettingsConfig>({
        defaultValues: {
            category: workflowRoot?.category ?? '',
            name: workflowRoot?.name ?? '',
            description: workflowRoot?.description ?? '',
            config: workflowRoot?.config ?? '',
        },
    });

    const handleSave = () =>
        form.handleSubmit((values) => {
            updateWorkflowSpec(values);
            saveWorkflow();
        })();

    return (
        <ActivityEditorLayout>
            <ActivityEditorLayout.Header>
                {workflowRoot?.name}
            </ActivityEditorLayout.Header>
            <ActivityEditorLayout.Content>
                <FormProvider {...form}>
                    <Form>
                        <FormTextInput
                            label={intl.common.category()}
                            fieldName="category"
                        />
                        <FormTextInput
                            label={intl.common.name()}
                            fieldName="name"
                        />
                        <FormTextArea
                            label={intl.common.description()}
                            fieldName="description"
                        />
                        <FormTextArea
                            label={intl.config.config()}
                            fieldName="config"
                        />
                    </Form>
                </FormProvider>
            </ActivityEditorLayout.Content>
            <ActivityEditorLayout.Content.Footer>
                <Button onClick={clearSelection} family="subtle">
                    {intl.common.cancel()}
                </Button>
                <Button onClick={handleSave}>{intl.common.save()}</Button>
            </ActivityEditorLayout.Content.Footer>
        </ActivityEditorLayout>
    );
}
