/* eslint no-param-reassign: 0 */

import { ActivityNav } from '@rf-smart-for-oraclecloud/wf-web';
import { setState } from '..';
import { DisplayType } from '../../types';

export const drillDownActivity = (navTo: ActivityNav) =>
    setState((state) => {
        state.navigation.push(navTo);
        state.selection = undefined;
    });

export const drillToActivity = (navTo: ActivityNav) =>
    setState((state) => {
        const targetBreadCrumbIndex = state.navigation.findIndex(
            (nav) => nav.key === navTo.key && nav.childKey === navTo.childKey,
        );
        state.navigation = state.navigation.filter(
            (_, index) => index <= targetBreadCrumbIndex,
        );
        state.selection = undefined;
    });

export const selectActivity = (activityKey: string) =>
    setState((state) => {
        state.selection = {
            type: 'activity',
            id: activityKey,
        };
    });

export const selectWorkflow = () =>
    setState((state) => {
        state.selection = {
            type: 'workflow',
        };
    });

export const selectAddActivity = (index: number) =>
    setState((state) => {
        state.selection = {
            type: 'addActivity',
            index,
        };
    });

export const clearSelection = () =>
    setState((state) => {
        state.selection = undefined;
    });

export const setDisplay = (display: DisplayType) =>
    setState(() => ({
        display,
    }));
