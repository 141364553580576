import { useState, useCallback } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { update } from '@@/api/workflows';
import { useAppIntl } from '@@/intl';
import { getState } from '../store';
import { loadWorkflow } from '../store/actions';

export function useSaveWorkflow() {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<UpdateWorkflowState>({
        loading: false,
    });

    const saveWorkflow = useCallback(async () => {
        const { workflow } = getState();
        if (workflow === undefined) return undefined;

        setData({ loading: true });
        const {
            id,
            version,
            category,
            name,
            description,
            config,
            activityTree,
            activityMap,
        } = workflow;

        const request = {
            id,
            category,
            name,
            description,
            version: version + 1,
            data: {
                config: JSON.parse(config),
                activityTree,
                activityMap,
            },
        };

        try {
            const response = await update(request);
            if (response !== undefined) {
                loadWorkflow(response);
            }
            toast.showSuccessToast(intl.profiles.workflowsSaveToast());
            return response;
        } catch {
            toast.showErrorToast(intl.profiles.workflowsSaveFailedToast());
            return undefined;
        } finally {
            setData((s) => ({ ...s, loading: false }));
        }
    }, [intl, toast]);

    return {
        saveWorkflow,
        ...data,
    };
}

type UpdateWorkflowState = {
    loading: boolean;
};
