import { useState } from 'react';
import { Button, TextInput } from '@rf-smart-for-oraclecloud/ui';
import { Modal } from '@rf-smart-for-oraclecloud/platform-ui';
import { BasicModalLayout } from '@@/layout/BasicModal';
import { useAppIntl } from '@@/intl';

export function DeleteModal({
    profileName,
    isOpen,
    onClose,
    onSubmit,
}: DeleteModalProps) {
    const [confirmText, setConfirmText] = useState('');
    const confirmDisabled = confirmText.toUpperCase() !== 'DELETE';

    const intl = useAppIntl();
    const handleClose = () => {
        setConfirmText('');
        onClose();
    };

    return (
        <Modal isOpen={isOpen}>
            <BasicModalLayout
                size="small"
                onClose={handleClose}
                actions={
                    <>
                        <Button variant="ghost" onClick={handleClose}>
                            {intl.common.cancel()}
                        </Button>
                        <Button isDisabled={confirmDisabled} onClick={onSubmit}>
                            {intl.common.yes()}
                        </Button>
                    </>
                }
                title={`${intl.profiles.deleteTitle({ name: profileName })}`}
            >
                {intl.profiles.deleteWarning()}
                <TextInput
                    autoFocus
                    style={{ marginTop: '1rem' }}
                    value={confirmText}
                    onChange={setConfirmText}
                />
            </BasicModalLayout>
        </Modal>
    );
}

type DeleteModalProps = Readonly<{
    profileName: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}>;
