import { ActvityView } from '../../components/ActivityView';
import { useWorkflowEditorStore } from '../../store';
import {
    removeActivity,
    selectActivity,
    drillDownActivity,
} from '../../store/actions';
import { selectCurrentActivities } from '../../store/selectors';
import { useSaveWorkflow } from '../useSaveWorkflow';

export function ActivityList() {
    const { saveWorkflow } = useSaveWorkflow();
    const activities = useWorkflowEditorStore(selectCurrentActivities);
    const selection = useWorkflowEditorStore((state) => state.selection);
    const selectedActivityId =
        selection?.type === 'activity' ? selection.id : undefined;

    const handleDelete = (key: string) => {
        removeActivity(key);
        saveWorkflow();
    };

    return activities.map((spec, index) =>
        spec === undefined ? undefined : (
            <ActvityView
                key={spec.key}
                spec={spec}
                index={index + 1}
                isSelected={selectedActivityId === spec.key}
                onDelete={handleDelete}
                onEdit={selectActivity}
                onDrillDown={drillDownActivity}
            />
        ),
    );
}
