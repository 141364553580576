/* eslint no-param-reassign: 0 */

import { WorkflowDetail } from '@@/types';
import { setState } from '..';

export const loadWorkflow = (workflow: WorkflowDetail) => {
    const { id, name, category, description } = workflow;
    const {
        version,
        data: { config, activityTree, activityMap },
    } = workflow.latestRevision;

    setState((state) => {
        state.workflow = {
            id,
            version,
            name,
            category,
            description,
            config: JSON.stringify(config, null, 4),
            activityTree,
            activityMap,
        };
    });
};

export const resetDisplay = () => {
    setState((state) => {
        state.display = 'design';
        state.selection = undefined;
        state.navigation = [{ key: 'root', childKey: 'default' }];
    });
};
