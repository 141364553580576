import { ListRequest, api } from '@rf-smart-for-oraclecloud/platform-ui';
import { apiCodes } from '@@/appConfig';
import {
    CreateWorkflowRequest,
    PagedResponse,
    UpdateWorkflowRequest,
    WorkflowDetail,
    WorkflowListItem,
} from '../types';

const apiCode = apiCodes.workflows;

export const list = ({
    filter,
    page,
    pageSize,
}: ListRequest): Promise<PagedResponse<WorkflowListItem>> =>
    api
        .get<
            PagedResponse<
                WorkflowListItem & { created: string; lastModified: string }
            >
        >(apiCode, `workflows?filter=${filter ?? ''}&page=${page ?? 1}&size=${pageSize ?? 20}`)
        .then((response) => ({
            ...response,
            items: response.items.map<WorkflowListItem>((wf) => ({
                ...wf,
                created: new Date(wf.created),
                lastModified: new Date(wf.lastModified),
            })),
        }));

export const get = (id: string) =>
    api.get<WorkflowDetail | undefined>(apiCode, `workflows/${id}`);

export const create = (workflow: CreateWorkflowRequest) =>
    api.post<WorkflowDetail | undefined>(apiCode, `workflows`, workflow);

export const update = (workflow: UpdateWorkflowRequest) =>
    api.patch<WorkflowDetail | undefined>(
        apiCode,
        `workflows/${workflow.id}`,
        workflow,
    );

export const remove = (id: string) => api.remove(apiCode, `workflows/${id}`);

export const listCategories = () =>
    api.get<string[]>(apiCode, 'workflows/categories');
