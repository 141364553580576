import { useState, useMemo } from 'react';
import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import { ActivityEditorLayout } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { plugins } from '@@/areas/config/plugins';
import { ActivitySearch } from './ActivitySearch';
import { FilteredActivities } from './FilteredActivities';
import './style.css';

export function AddActivitySettings() {
    const intl = useAppIntl();
    const [filter, setFilter] = useState('');
    const categorizedActivites = useMemo(
        () => categorizeActivites(filter),
        [filter],
    );

    return (
        <ActivityEditorLayout className="wf-editor-add-activity">
            <ActivityEditorLayout.Header>
                {intl.config.addActivity()}
            </ActivityEditorLayout.Header>
            <ActivityEditorLayout.Content noPad>
                <ActivitySearch onSearch={setFilter} />
                <FilteredActivities activities={categorizedActivites} />
            </ActivityEditorLayout.Content>
        </ActivityEditorLayout>
    );
}

function categorizeActivites(filter: string) {
    const filterRegex = new RegExp(filter, 'i');
    const activityFilter = (item: WorkflowActivity) =>
        filterRegex.test(item.name) ||
        filterRegex.test(item.description) ||
        filterRegex.test(item.category);

    return Object.values(plugins.activities).reduce(
        (result, activity) => {
            const categoryActivities = result[activity.category] ?? [];
            if (activityFilter(activity)) {
                categoryActivities.push(activity);
            }

            return {
                ...result,
                [activity.category]: categoryActivities,
            };
        },
        {} as Record<string, WorkflowActivity[]>,
    );
}
