import { useState, useCallback } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { CreateWorkflowFormData } from '@@/types';
import { useAppIntl } from '@@/intl';
import { create } from '@@/api/workflows';

export function useCreateWorkflow() {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<CreateWorkflowState>({
        loading: false,
    });

    const createWorkflow = useCallback(
        (request: CreateWorkflowFormData) => {
            setData({ loading: true });
            return create({
                ...request,
                data: {
                    activityTree: [{ key: 'root', children: { default: [] } }],
                    activityMap: {
                        root: {
                            key: 'root',
                            id: 'root',
                            type: 'workflow',
                            name: request.name,
                            description: request.description,
                        },
                    },
                    config: {},
                },
            })
                .catch(() =>
                    toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
                )
                .finally(() => setData((s) => ({ ...s, loading: false })));
        },
        [intl, toast],
    );

    return {
        createWorkflow,
        ...data,
    };
}

type CreateWorkflowState = {
    loading: boolean;
};
