import { WorkflowDefinitionDesigner } from './Designer';
import { useWorkflowEditorStore } from '../../store';
import { WorkflowDefinitionCodeBehind } from './CodeBehind';
import './style.css';

export function WorkflowDefinition(_: WorkflowDefinitionProps) {
    const display = useWorkflowEditorStore((s) => s.display);

    return (
        <>
            {display === 'design' && <WorkflowDefinitionDesigner />}
            {display === 'code' && <WorkflowDefinitionCodeBehind />}
        </>
    );
}

type WorkflowDefinitionProps = Readonly<{}>;
