/* eslint no-param-reassign: 0 */

import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import {
    ActivityNode,
    ActivitySpecEdit,
    isWebActivity,
} from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/areas/config/plugins';
import { setState } from '..';
import { selectCurrentActivityNodes } from '../selectors';
import { WorkflowSettingsConfig } from '../../types';

export const addActivity = (activity: WorkflowActivity) =>
    setState((state) => {
        if (state.workflow === undefined) return;

        const index =
            state.selection?.type === 'addActivity'
                ? state.selection?.index
                : undefined;

        if (index === undefined) return;

        const spec = createSpec(activity);
        const navNode: ActivityNode = {
            key: spec.key,
        };

        if (
            isWebActivity(activity) &&
            activity.settings.updateChildren !== undefined
        ) {
            navNode.children = activity.settings.updateChildren(spec);
        }

        const currentActivities = selectCurrentActivityNodes(state);
        currentActivities.splice(index, 0, navNode);

        state.workflow.activityMap[spec.key] = spec;
        state.selection = {
            type: 'activity',
            id: spec.key,
        };
    });

export const removeActivity = (activityKey: string) =>
    setState((state) => {
        if (state.workflow === undefined) return;

        const currentActivities = selectCurrentActivityNodes(state);
        const indexToRemove = currentActivities.findIndex(
            (a) => a.key === activityKey,
        );
        currentActivities.splice(indexToRemove, 1);

        delete state.workflow.activityMap[activityKey];
        if (
            state.selection?.type === 'activity' &&
            state.selection.id === activityKey
        ) {
            state.selection = undefined;
        }
    });

export const updateActivitySpec = (spec: ActivitySpecEdit) =>
    setState((state) => {
        if (state.workflow === undefined) return;

        const updatedSpec = {
            ...state.workflow.activityMap[spec.key],
            ...spec,
        };
        state.workflow.activityMap[spec.key] = updatedSpec;

        const activity = plugins.activities[spec.type];
        if (
            !isWebActivity(activity) ||
            activity.settings.updateChildren === undefined
        ) {
            return;
        }

        const currentNode = selectCurrentActivityNodes(state).find(
            (s) => s.key === spec.key,
        );
        if (currentNode === undefined) {
            return;
        }

        currentNode.children = activity.settings.updateChildren(
            spec,
            currentNode.children,
        );
    });

export const updateWorkflowSpec = (values: WorkflowSettingsConfig) =>
    setState((state) => {
        if (state.workflow === undefined) return;

        state.workflow = {
            ...state.workflow,
            ...values,
        };
    });

function createSpec(activity: WorkflowActivity): ActivitySpecEdit {
    let spec: ActivitySpecEdit = {
        key: globalThis.crypto.randomUUID(),
        type: activity.type,
        id: activity.type,
        name: activity.name,
        description: '',
    };

    if (isWebActivity(activity) && activity.settings !== undefined) {
        spec = {
            ...spec,
            ...activity.settings.init(),
        };
    }

    return spec;
}
