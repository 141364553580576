import { Layout } from '@rf-smart-for-oraclecloud/ui';
import { Outlet } from 'react-router-dom';
import {
    AppHeader,
    ProtectedRoute,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { useAppIntl } from '@@/intl';
import { writeAccounts } from '@@/permissions';
import { Menu } from './Menu';

const app = 'Workflows';

const accountsBaseUrl =
    import.meta.env.BASE_URL === '/'
        ? import.meta.env.VITE_ACCOUNTS_URL
        : `${window.location.origin}/accounts`;

const workflowAdminBaseUrl =
    import.meta.env.BASE_URL === '/'
        ? '/'
        : `${window.location.origin}/workflow-admin`;

export function AppLayout({ area }: AppLayoutProps) {
    const intl = useAppIntl();

    const appMenuItems = [
        {
            name: intl.menu.app_accounts(),
            route: accountsBaseUrl,
        },
        {
            name: intl.menu.app_workflowAdmin(),
            route: workflowAdminBaseUrl,
        },
    ];

    return (
        <Layout>
            <Layout.Header>
                <AppHeader app={app} menuItems={appMenuItems} tenantRequired />
            </Layout.Header>
            <Layout.Aside>
                <Menu area={area ?? ''} />
            </Layout.Aside>
            <Layout.Body>
                <ProtectedRoute permissions={writeAccounts}>
                    <Outlet />
                </ProtectedRoute>
            </Layout.Body>
        </Layout>
    );
}

type AppLayoutProps = Readonly<
    React.PropsWithChildren<{
        area?: string;
    }>
>;
