import { useMemo } from 'react';
import { TextArea } from '@rf-smart-for-oraclecloud/ui';
import { convertWorkflow } from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/areas/config/plugins';
import { useAppIntl } from '@@/intl';
import { useWorkflowEditorStore } from '../../store';

export function WorkflowDefinitionCodeBehind() {
    const workflow = useWorkflowEditorStore((state) => state.workflow);
    const intl = useAppIntl();

    const configPreview = useMemo(() => {
        const config =
            workflow === undefined
                ? undefined
                : {
                      activityTree: workflow.activityTree,
                      activityMap: workflow.activityMap,
                  };
        return JSON.stringify(config, null, 4);
    }, [workflow]);

    const workflowPreview = useMemo(() => {
        const preview =
            workflow === undefined
                ? undefined
                : convertWorkflow(
                      {
                          id: workflow.id,
                          name: workflow.name,
                          category: workflow.category,
                          description: workflow.description,
                          latestRevision: {
                              id: '',
                              version: workflow.version,
                              data: {
                                  activityTree: workflow.activityTree,
                                  activityMap: workflow.activityMap,
                              },
                              dependencies: [],
                          },
                      },
                      plugins,
                  );
        return JSON.stringify(preview, null, 4);
    }, [workflow]);

    return (
        <div className="wf-editor-definition-code">
            <TextArea
                label={intl.config.config()}
                value={configPreview}
                aria-label={intl.config.codeBehind()}
            />
            <TextArea
                label={intl.config.workflow()}
                value={workflowPreview}
                aria-label={intl.config.codeBehind()}
            />
        </div>
    );
}
